@mixin glassmorphism {
  background: rgba(203, 203, 203, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.096);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
}

@mixin basicFlex {
  display: inline-flex;
  flex-direction: column;
}
