@font-face {
  font-family: "Shrikhand";
  font-display: block;
  src: local("Shirkhand"), url("../assets/Font.otf") format("truetype");
}

// Global Styles Variables
$green: #8bd76b;
$yellow: #eef46e;
$red: #f54768;
$degreeShift: -15deg;
