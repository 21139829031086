//animations
@keyframes slideDown {
  0% {
    transform: translateY(-150px);
  }

  90% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes floating {
  0% {
    transform: rotate(-10deg) translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(2px, -3px, 0);
  }
}

@keyframes floating {
  0% {
    transform: translate3d(0px, 2px, 10px);
  }

  100% {
    transform: translate3d(30px, 25px, 2px);
  }
}
