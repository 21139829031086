@import './variables.scss';
@import "./mixins.scss";
@import "./animations.scss";
@import "./responsive.scss";


html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  display: flex;
  font-family: Waka Sans, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(120deg, rgb(23, 32, 32), rgb(25, 26, 49));
  background-size: cover;
  color: rgb(13, 12, 14);
}

// Blur animation
.floatingBackground,
.floatingBackground2 {
  position: fixed;
  width: 100%;
  z-index: -2;
  top: 10%;
  animation: floating 40s ease-in alternate-reverse infinite;
  filter: blur(50px);
}

.floatingBackground2 {
  top: 40%;
  right: 50%;
}


h1 {
  span {
    min-width: 20px;
  }

  font-family: Shrikhand,
  sans-serif;
  font-size: 4rem;
  display: inline;
  margin-top: 10px;
  color: #ffffff;
  text-align: center;
  position: relative;
  letter-spacing: 0.1rem;

  &::after,
  &::before {
    border-left-width: 10px;
    border-right-width: 10px;
    border-bottom-width: 10px;
    display: block;
    position: absolute;
    right: -1rem;
    transform: rotate(60deg);
    bottom: 0;
    content: "";
    border-left-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    transform-origin: center;
    border-bottom-color: $red;
    animation: floating 5s ease alternate-reverse infinite;
  }

  &::after {
    right: 0px;
    bottom: -1px;
    transform: rotate(-10deg);
    border-bottom-color: $green;
    animation-delay: 1s;
  }
}

//removes triangles and gradient from H1 tags
.noFancy {
  margin-bottom: -15px;

  &::after,
  &::before {
    display: none;
  }
}

p {
  letter-spacing: -0.05rem;
  font-size: 1.3rem;
  font-family: "Roboto Mono", sans-serif;
  line-height: 125%;
}

.drawerTitle{
  color: white;
  margin-left: 16px;
}

//slidingAnimation
.slideList {
  margin: 10px;

  ul {
    @include basicFlex;
  }

  li {
    width: 100%;
    max-width: 1000px;
    min-width: 300px;
    text-transform: capitalize;
    list-style: none;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 20px;

    h4 {
      margin: 2px 0px;
      font-weight: 800;
      font-size: 2rem;
      letter-spacing: -0.1rem;
      padding: 0.5rem;
      color: rgb(255, 255, 255);
      text-align: center;
      text-shadow: 2px 2px 2px black;
    }
  }
}




#searchBar-label {
  color: white !important;
}

#searchBar {
  min-width: 320px;
  display: flex;
  color: white;
}

// drawer ui
.customDrawerStyleTweaks {
  width: 100%;

  .MuiListItem-button {
    display: inline-flex;
    align-items: unset;

    &:hover {
      background-color: rgba(230, 230, 230, 0.5);
    }
  }

  svg {
    filter: invert(1);
  }
}

span {
  display: inline-flex;
}

//journal and projects pages layout
.journal {
  padding-top: 50px;
  min-height: 100vh;
  color: white;
}

.journal,
.projects {
  overflow: hidden;
  @include basicFlex;
  max-width: 95%;
  align-items: center;
}

#root {
  @include basicFlex;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;

  .Home {
    width: 100%;
  }

  .homePosts,
  .homeProjects {
    display: grid;
    padding: 100px 0px;
    margin-top: 100px;
    width: 100vw;
    grid-template-rows: auto auto;
    margin-top: 10px;
    grid-template-columns: minmax(10px, 25%) 1fr;
    position: relative;

    h1 {
      grid-area: 1/1/1/1;
      transform: rotate(-90deg) scale(1) translatey(-3rem);
      display: inline-flex;
      width: fit-content;
      place-self: center;
    }

    .twoBytwo {
      margin: 1rem;
      max-width: 1000px;
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(2, minmax(360px, 50%));
      grid-gap: 1rem;

      col .card {
        opacity: 0;
        animation: fadeIn 0.5s 2s linear forwards;
      }
    }

    .containerContent {
      @include basicFlex;
      @include glassmorphism;
    }

    img {
      aspect-ratio: 3/2;
      object-fit: fill;
    }

    p,
    a {
      color: white;
    }

    .linkButton {
      grid-area: 2/2/2/2;
      display: grid;
      // place-items: center;
      // justify-self: flex-end;
      margin-right: 15vw;
      position: relative;
      width: 100%;

      a {
        border: 1px solid rgb(141, 141, 141);
        color: rgb(141, 141, 141);
        transition: 0.3s;
        margin-top: 25px;
        max-width: 200px !important;
        place-self: center;

        &:hover {
          border: 1px solid white;
          color: white;
        }
      }
    }
  }

  .homeProjects {
    background: rgba(0, 0, 0, 0.411);
    grid-template-columns: auto auto;

    h1 {
      transform: rotate(90deg) translatey(-1rem);
      grid-area: 1/2/1/2;
    }

    .linkButton {
      grid-area: 2/1/2/2;
    }
  }

  .homeLanding {
    margin: 0 auto;
    margin-top: 15vh;
    min-height: 100vh;
    width: 100%;
    max-width: 1200px;
    font-size: calc(10px + 1.5vmin);
    color: #ffffff;
    display: grid;
    place-content: center;
    grid-template-columns: 40% 40%;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-radius: 50px;

    .div1 {
      grid-area: 1 / 1 / 2 / 2;
      position: relative;
      z-index: 10;
      top: 5vh;
      margin: 15px;

      h1 {
        text-align: left;
        display: inline-flex;
        animation: fadeIn 1.5s;
        margin-bottom: 0px;

        &:nth-child(2) {
          opacity: 0;
          animation: slideDown 2s 1s forwards;
        }
      }

      p {
        color: rgb(199, 195, 195);
      }

      p,
      button {
        opacity: 0;
        animation: fadeIn 2s 2s forwards;
      }

      #scroll {
        opacity: 0;
        animation: fadeIn 2s 4s forwards;
        position: absolute;
        left: -125px;
        bottom: 90px;
        transform: rotate(90deg);
        width: 300px;
        font-size: 1rem;

        svg {
          stroke: rgba(255, 255, 255, 0.5);
          stroke-dasharray: 50;
          stroke-dashoffset: 49;
          stroke-width: 3;
          animation: dash 4s ease alternate-reverse infinite;
        }
      }
    }



    .div2Overlay {
      opacity: 0;
      animation: fadeIn 1s ease-in 2.4s forwards;
      grid-area: 1 / 2 / 3 / 3;
      position: relative;
      z-index: 4;

      svg {
        position: relative;
        top: -15px;
        right: -150px;
        z-index: 1;

        &:nth-child(2n) {
          right: 130px;
          top: 50%;
          transform: rotate(100deg);
        }
      }
    }

    .div2 {
      grid-area: 1 / 2 / 3 / 3;
      z-index: 5;
      animation: fadeIn 1s ease-in 1.4s forwards;
      opacity: 0;
      position: relative;
      overflow: hidden;
      object-fit: cover;
      max-width: 100%;
      min-height: 100vh;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: (-400px);
      background-position-y: -350px;
      clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
      border-radius: 50px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.MuiFormControl-root {
  border: 1px solid rgba(255, 255, 255, 0.212) !important;
  max-width: 320px;
}

.JournalGraph {
  display: inline-flex;
  flex-direction: column;
  background-color: #2e2c2c64;
  border: 1px solid white;
  border-radius: 25px;
  overflow: hidden;

  svg {
    overflow: visible;
    width: 320px;
    position: relative;
    left: 30%;
  }

  h2 {
    text-decoration: underline;
  }

  h2,
  p {
    text-shadow: 1px 1px 1px black;
    color: white;
    font-size: 1.3rem;
    margin: 10px;
  }
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  column-gap: 15px;
  row-gap: 15px;
  justify-content: center;
  max-width: 1200px;
  min-width: 320px;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;

  .card {
    //initial state should be hidden
    width: 100%;
    height: 100%;
    min-height: 320px;
    min-width: 320px;
    max-width: 500px;
    opacity: 0;
    animation: fadeIn 0.5s linear forwards;
    background-color: rgba(0, 0, 0, 0.075);
    // background-image: url("https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60");
    content: "";

    a,
    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    p {
      padding: 0px;
    }
  }
}

.direction-reveal__img {
  height: 100% !important;
  width: 100%;
}

.direction-reveal__card {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.direction-reveal__overlay {
  width: 100% !important;
  padding: 0px !important;
  overflow-y: auto !important;

  .actions,
  h3,
  p {
    margin: 10px 5px 3px 5px;
  }
}

#mainApp {
  @include basicFlex;
  align-items: center;
}



.contactForm {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 1111111111111111;
  background: rgba(0, 0, 0, 0.7); // Dark background for contrast
  color: white;
  border: none;

  .closeButton {
    position: absolute;
    top: 15px;
    right: 50px;
    background: transparent;
    border: none;
    font-size: 2.5em;
    cursor: pointer;
    color: white;

    &:hover {
      color: #ff5757; // Color changes when hovered
    }
  }

  form {
    background: #333; // Dark form background
    padding: 1em 2em;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px; // Fixed width

    label {
      font-weight: bold;
      margin-bottom: 0.5em;
      display: block;
    }

    input,
    textarea {
      width: 100%;
      padding: 0.5em;
      margin-bottom: 1em;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    input[type="submit"] {
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
      padding: 0.5em 1em;
      border-radius: 4px;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

// styles.scss
.navbar {
  z-index: 100;
  list-style: none;
  color: white;
  display: flex;
  justify-content: flex-end;
  right: 0px;
  top: 0px;
  width: 100vw;
  position: fixed;
}

.navItem {
  margin: 25px;
  transition: 0.3s;
  color: white;
  cursor: pointer;

  &:hover {
    color: #eef46e;
    transition: 0.3s;
  }
}

.textCard {
  margin: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  width:100%;
  transition: background-color 0.3s ease;
  color: #ffffff;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(96, 89, 89, 0.66);
  transition: background-color 0.3s ease;
  animation: fadeIn 0.5s ease forwards;

  &:hover {
    background-color: #f0f0f025;
    transform: scale(1.005);
  }
  h3,p,h4{
    margin: 14px;
  }
  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
  }
}
